import httpProvider from 'Providers/http.js';

export default {
    getUnverifiedContactsByOrganization(organizationId) {
        return new Promise((resolve, reject) => {
            httpProvider.get(`/user/contacts/unverified?organizationId=${organizationId}`).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    getContactsByOrganization(organizationId, propertyId = null, sipServerId = null) {
        const params = { organizationId: organizationId };
        
        if (propertyId) {
            params.propertyId = propertyId;
        }

        if (sipServerId) {
            params.sipServerId = sipServerId;
        }
        
        return new Promise((resolve, reject) => {
            httpProvider.get('/user/contacts?' + new URLSearchParams(params).toString())
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    getContacts(filters = {}) {
        return new Promise((resolve, reject) => {
            httpProvider.get('/user/contacts?' + new URLSearchParams(filters).toString())
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    updateContact(data) {
        return new Promise((resolve, reject) => {
            httpProvider.put('/user/contact/update/' + data.id, data)
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    verifyContacts(data) {
        return new Promise((resolve, reject) => {
            httpProvider.put('/user/verify', data)
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    removeContacts(data) {
        return new Promise((resolve, reject) => {
            httpProvider.delete('/user/contact/delete/' + data).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch(error => {
                reject(error);
            })
        });
    }
    
}